import {Tracer} from "../Tracer";

export class Validate {

    constructor() {
    }

    /**
     * Validates email address using regular expressions.
     * @param emailAddress
     * @returns {boolean}
     */
    static email(emailAddress){

        try{
            const r = Validate._EMAIL_REGEX;
            return r.test(String(emailAddress).toLowerCase());
        }
        catch(e){
            Tracer.error("4QSEH3M-(email_validation): Failed to validate email using regex.");
            throw e;
        }
    }

    static phoneNumber(phoneNumber){
        throw new Error("not implemented yet!");
    }

    static zipCode(zipCode){
        throw new Error("not implemented yet!");
    }

    static number(n){
        throw new Error("not implemented yet!");
    }

    /**
     * Return the regular expression for validating email.
     * @returns {RegExp}
     * @private
     */
    static get _EMAIL_REGEX(){
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }


}
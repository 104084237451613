import {StorageConfig} from "./StorageConfig";

export class StorageBase{


    constructor(storageConfig){

        this._config = null;
        if (storageConfig == null){
            this._config = new StorageConfig();
        }
        else{
            if ( !(storageConfig instanceof StorageConfig) )
            {
                throw new Error("Config must be an instance of 'StorageConfig'")
            }
            this._config = storageConfig;
        }
    }

    get config(){
        return this._config;
    }

    internalFireChange(name, value){

    }

    internalFireError(name, value, e){

    }

    internalSetItem(name, value){
        throw new Error("[StorageBase:set] Method not overridden or implemented");
    }
    
    internalGetItem(name){
        throw new Error("[StorageBase:get] Method not overridden or implemented");
    }

    internalRemoveItem(name){
        throw new Error("[StorageBase:set] Method not overridden or implemented");
    }

    internalClearAll(){
        throw new Error("[StorageBase:set] Method not overridden or implemented");
    }


    get _getCallbackStorage(){

        if (window.__local_callback_store == null) {

            window.__local_callback_store = new Map();

            if (this.config.debug === true){
                console.debug(`[StorageLocalInstance:_getCallbackStorage] new Map() created and pinned to the window object` );
            }
        }


        return window.__local_callback_store;
    }



}
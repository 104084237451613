import { Zt } from 'local-zt';

/**
 * Event bus proxy. <br>
 *
 * Use this proxy in case we want to change the event bus library.
 */
export class EventBus {

    static on(name, callbackFn){
        Zt.listen(name, callbackFn);
    }

    static emit(name, value){
        Zt.notify(name, value);
    }

}
import 'flex.box';


import {default as properties} from './_properties';
import {CookieConsentHttp} from "@/components/CookieConsent/_http/CookieConsentHttp";
import {Tracer} from "local-common/Tracer";


export default {
    name: "CookieConsent",

    data() {
        return {
            properties: properties,
            cookieExist: true
        }
    },

    props: {

    },

    mounted(){

        // for clarity’s sake.
        // The properties of this component are configured on the _properties.js
        let props = {
            baseUrl: properties.baseUrl,
            sessionKey: properties.sessionKey
        };

        let cookieConsentHttp = new CookieConsentHttp(props);

        let vm = this;
        cookieConsentHttp.checkCookie().then(result => {
            vm.cookieExist = result;
            Tracer.debug(`[dbg-166HIV4M] [COOKIE_CONSENT_COMPONENT]::[CHECK_COOKIE]:[RESULT=>${result}]`);
        }).catch(err => {
            vm.cookieExist = false;
            Tracer.error("[err-82VG3W4] [COOKIE_CONSENT_COMPONENT]::[CHECK_COOKIE] Error when connecting to API for checking for cookie.");
            Tracer.error(err);
        });

    },
    methods: {
        accept(){
            let props = {
                baseUrl: properties.baseUrl,
                sessionKey: properties.sessionKey
            };



            let cookieConsentHttp = new CookieConsentHttp(props);
            let vm = this;
            cookieConsentHttp.setCookie().then(result => {
                vm.cookieExist = result;
                Tracer.debug(`[dbg-LWNWRGW] [COOKIE_CONSENT_COMPONENT]::[SET_COOKIE]:[RESULT=>${result}]`);
            }).catch(err => {
                Tracer.error("[err-CEC3017] [COOKIE_CONSENT_COMPONENT]::[SET_COOKIE] Error setting cookie");
                Tracer.error(err);
            });
        },
        reject(){
            window.location.href = `${properties.baseUrl}/no-cookies-notice.cfm`;
        }
    }
}
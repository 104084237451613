/**
 * Bootstrap Style Modal Component for Vue
 * Depend on Bootstrap.css
 */
export default {
    name: "ModalDialog",
    props: {
        show: {
            type: Boolean,
            twoWay: true,
            default: false
        },
        title: {
            type: String,
            default: 'Modal'
        },
        small: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },

        force: {
            type: Boolean,
            default: false
        },

        transition: {
            type: String,
            default: 'modal'
        },

        okText: {
            type: String,
            default: 'OK'
        },

        cancelText: {
            type: String,
            default: 'Cancel'
        },

        okClass: {
            type: String,
            default: 'btn blue'
        },

        cancelClass: {
            type: String,
            default: 'btn red btn-outline'
        },

        closeWhenOK: {
            type: Boolean,
            default: false
        },
        closeOnX: {
            type: Boolean,
            default: false
        },
        hideCancel: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            duration: null
        };
    },
    computed: {
        modalClass () {
            return {
                'modal-lg': this.large,
                'modal-sm': this.small,
                'modal-full': this.full
            }
        }
    },
    created () {
        if (this.show) {
            document.body.className += ' modal-open';
        }
    },
    beforeDestroy () {
        document.body.className = document.body.className.replace(/\s?modal-open/, '');
    },
    watch: {
        show (value) {

            if (value) {
                document.body.className += ' modal-open';
            }

            else {
                if (!this.duration) {
                    this.duration = window.getComputedStyle(this.$el)['transition-duration'].replace('s', '') * 1000;
                }
                window.setTimeout(() => {
                    document.body.className = document.body.className.replace(/\s?modal-open/, '');
                }, this.duration || 0);
            }
        }
    },
    methods: {
        ok() {
            this.$emit('ok');
        },
        close() {
            this.$emit('close');
        },
        cancel() {
            this.$emit('cancel');
        },
        clickMask() {
            if (!this.force) {
                this.cancel();
            }
        }
    }
};
import {StorageBase} from "./StorageBase";
import {StorageInstance} from "./StorageInstance";
import {StoredItem} from "./StoredItem";

export class StoragePersistent extends StorageBase {

    constructor(storageConfig) {
        super(storageConfig);
        this.instanceStorage = new StorageInstance(storageConfig);
    }

    get config(){
        return super.config;
    }

    internalFireChange(name, value){
        super.internalFireChange(name, value);
    }

    internalFireError(name, value, e){
        super.internalFireError(name, value, e);
    }

    internalSetItem(name, item){
        try {

            if (name == null || item == null){
                return;
            }

            if (name.length === 0){
                return;
            }

            let localName = this._generateUniqueName(name);

            let value = item.value;

            if (this._isObject(value)){
                sessionStorage.setItem(localName, JSON.stringify(value));
            }
            else{
                sessionStorage.setItem(localName, value);
            }

            this.instanceStorage.internalSetItem(name, item);

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalExistItem(name){
        try {

            if (name == null){
                return false;
            }

            if (name.length === 0){
                return false;
            }

            let existLocally = this.instanceStorage.internalExistItem(name);

            let localName = this._generateUniqueName(name);
            let obj = sessionStorage.getItem(localName);

            if (this.config.debug === true){
                if (obj != null) {
                    console.debug(`local storage key ${name} exists`);
                }
                else{
                    console.debug(`local storage key ${name} does not exists`);
                }
            }

            if (existLocally && obj != null){
                return true;
            }

            if (obj != null && !existLocally){
                let tmpObj = this.internalGetItem(name);

                let item = new StoredItem(name, tmpObj)
                this.instanceStorage.internalSetItem(name, item);

                return true;
            }

            if (obj == null && existLocally){
                let tmpObj = this.instanceStorage.internalGetItem(name);

                if (tmpObj != null) {
                    this.internalSetItem(name, tmpObj.value);
                }

                return true;
            }

            return false;

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalGetItem(name){
        try {

            if (name == null ){
                return null;
            }

            if(name.length === 0){
                return null;
            }

            let localName = this._generateUniqueName(name);
            let obj = this.instanceStorage.internalGetItem(name);

            if (obj != null){

                if (this.config.debug === true){
                    console.debug(`[StorageLocalStorage:internalGetItem] Object retrieved from memory` );
                }

                let tmpObj = sessionStorage.getItem(localName);
                if (tmpObj == null){
                    if (this._isObject(value)){
                        sessionStorage.setItem(localName, JSON.stringify(obj.value));
                    }
                    else{
                        sessionStorage.setItem(localName, obj.value);
                    }
                }

                return obj;
            }

            let tmpObj = sessionStorage.getItem(localName);
            let tmpValue = null;

            if (tmpObj != null){
                if (tmpObj.charAt(0) === '{'){
                    try {
                        tmpValue = JSON.parse(tmpObj);
                    }
                    catch(e){
                        if (this.config.debug === true){
                            console.debug(`[StorageLocalStorage:internalGetItem] Failed to parse value to an object. Value ${tmpObj}` );
                        }
                        console.error(e);
                    }
                }
                else{
                    tmpValue = tmpObj;
                }

                let item = new StoredItem(localName, tmpValue);
                this.instanceStorage.internalSetItem(name, item);

                if (this.config.debug === true){
                    if (obj != null) {
                        console.debug(`[StorageLocalStorage:internalGetItem] key ${name}. An object of type ${typeof obj} retrieved` );
                    }
                    else{
                        console.debug(`[StorageLocalStorage:internalGetItem] key ${name}. Value is null` );
                    }
                }

                return item;

            }

            return null;

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalRemoveItem(name){
        try {

            if (name == null || name.length === 0){
                return null;
            }

            let name = this._generateUniqueName(name);
            sessionStorage.removeItem(name);
            this.instanceStorage.internalRemoveItem(name);

            if (this.config.debug === true){
                console.debug(`[StorageLocalStorage:internalRemoveItem] key ${name} removed` );
            }

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalClearAll() {
        try {

            sessionStorage.clear();

            if (this.config.debug === true) {
                console.debug(`[StorageLocalStorage:internalClearAll] clear all`);
            }
        }
        catch (e) {
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    _generateUniqueName(name){
        return `${this.config.prefix}_p_${name}`;
    }

    _isObject(value){
        return (typeof value === 'object');
    }
}
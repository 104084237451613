import {PageSettings} from "local-common/PageSettings";


/**
 * Represents the properties for this modules
 */
export default {

    /**
     * Gets the baseUrl for communicating with the API
     */
    baseUrl: PageSettings.get("baseUrl"),
    /**
     * Gets the sessionKey for accessing the API
     */
    sessionKey: PageSettings.get("sessionKey"),

    consentText: "By clicking \"Accept All\", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",

}
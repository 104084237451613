/**
 * Represents a logger.
 */
export class Tracer {

    /**
     * Sets the environment as development
     */
    static setDevEnvironment(){
        window.__tracer_isDev = true;
    }

    /**
     * Write an error message.
     * @param message
     */
    static error(message){

        if (message == null){
            return;
        }

        console.error(message);
    }

    static warning(message){

        if (message == null){
            return;
        }

        console.warn(message);
    }

    static isDebugEnabled(){
        return (window.__tracer_isDev === true);
    }

    /**
     * Write debug logs.
     *
     * Note: It only writes debug log on development mode
     * @param message
     */
    static debug(message){

        if (window.__tracer_isDev === true) {
            if (message == null) {
                return;
            }

            console.debug(message);
        }
    }

    /**
     * Write debug logs.
     *
     * Note: It only writes verbose log on development mode
     * @param message
     */
    static verbose(message){
        if (window.__tracer_isDev === true){
            if (message == null){
                console.debug('<<message empty>>');
                return;
            }

            console.debug(message);
        }
    }

    static info(message){
        if (message == null){
            return;
        }

        console.info(message);
    }

}
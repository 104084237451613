import {HttpUtils} from "local-common/HttpUtils";
import {StringUtils} from "local-common/StringUtils";
import {Tracer} from "local-common/Tracer";
import * as moment from "moment";

/**
 * Connects to end point API via http <br />
 * @internal This methods is meant to be access from CookieConsent component only
 */
export class CookieConsentHttp {

    /**
     * Initialize this class
     * @param props An object that contains the following fields:
     *      `baseUrl` - The base url where the endpoint is hosted
     *      `sessionKey` - the session key for the end point
     */
    constructor(props){

        this.baseUrl = props.baseUrl;
        this.sessionKey = props.sessionKey;

        if (StringUtils.isEmptyOrNull(this.baseUrl)){
            throw new Error("[err-7RB9P36] [MISSING_INIT_PROPERTIES] baseUrl required for initializing CookieConsentHttp");
        }

        if (StringUtils.isEmptyOrNull(this.sessionKey)){
            throw new Error("[err-TD4LUHQ] [MISSING_INIT_PROPERTIES] 'SessionKey' is required for initializing CookieConsentHttp");
        }

    }

    /**
     * Checks if a consent cookie exists.  <br />
     * This method will ask the server if a httponly cookie for consent has been placed
     * on the browser and will return true if it has or false.
     * @returns {Promise<boolean>}
     */
    checkCookie(){

        return new Promise( (resolve, reject) => {

            let url = `${this.baseUrl}/components/cookieConsent.cfc?method=check&sessionKey=${this.sessionKey}`;
            HttpUtils.get(url).then( data => {

                if (data === true){
                    Tracer.debug("[dbg-52YF4T] [CHECK_CONSENT_COOKIE]::[COOKIE_EXISTS => True]: Cookie found");
                    resolve(true);
                }
                else {
                    Tracer.debug("[dbg-GG0JAK0] [CHECK_CONSENT_COOKIE]::[COOKIE_EXISTS => False]: Cookie not found");
                    resolve(false)
                }

            }).catch(err => {
                Tracer.error("[err-JTLQJL] [CHECK_CONSENT_COOKIE] Error when connecting to the server to check for cookie existence");
                Tracer.error(err);
                reject(err);
            });
        });

    }

    setCookie(){
        return new Promise( (resolve, reject) => {


            let localDate = moment();
            let z = localDate.format('Z');
            let isoDate = localDate.toISOString(true);

            let url = `${this.baseUrl}/components/cookieConsent.cfc?method=setCookie&sessionKey=${this.sessionKey}&dt=${encodeURIComponent(isoDate)}&z=${encodeURIComponent(z)}`;
            HttpUtils.get(url).then( data => {


                if (data === true){
                    Tracer.debug("[dbg-ZVC491A] [SET_CONSENT_COOKIE]::[RESULT=>True]: Success");
                    resolve(true);
                }
                else {
                    Tracer.debug("[dbg-4G40HME] [SET_CONSENT_COOKIE]::[RESULT=>False]: Failed");
                    resolve(false)
                }

            }).catch(err => {
                Tracer.error("[err-3P1W5WG] [SET_CONSENT_COOKIE] Error when connecting to the server to check for cookie existence");
                Tracer.error(err);
                reject(err);
            });
        });
    }
}
export class StoredItem{

    constructor(name, value, func) {

        this._name = name;
        this._value = value;
        this._oldValue = value;
        this._listeners = [];

        if (func != null) {
            this.registerListener(func);
        }

    }

    get name(){
        return this._name;
    }

    get value() {
        return this._value;
    }

    set value(newValue){

        if (this._value !== newValue) {

            this._oldValue = this._value;
            this._value = newValue;

            let vm = this;
            this._listeners.forEach(function(listener){
                try {
                    listener(vm._value);
                }
                catch(err){
                    console.error(err);
                }
            })
        }
    }

    push(obj){

        if (this._listeners.length === 0){

            console.debug(`[ZT:295d347] No callback(s) associated with ${this._name} entry`);
            return;
        }

        for(let i = 0; i <= this._listeners.length; i++){
            let callback = this._listeners[i];

            if (callback == null){
                console.debug(`[ZT:47d118e] Undefined callback for slot ${i} for ${this._name} entry`);
                continue;
            }

            try {
                callback(obj);
                console.debug(`[ZT:2ee1e15] callback for slot ${i} for ${this._name} entry *Invoked*`);
            }
            catch(err){
                console.error(err);
            }
        }

    }

    registerListener(func){
        if (typeof func === 'function') {
            this._listeners.push(func);
            console.debug(`[ZT:5d32c5b] Listener added for ${this.name}`);
        }
        else{
            console.warn("[ZT:59f0941] Listener is not a function");
        }
    }

}
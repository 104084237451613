export class StorageConfig{



   constructor() {
       this._prefix = "zt:";
       this._duration = 20;
       this._debug = true;
   }

   get prefix(){
       return this._prefix;
   }

    /**
     *
     * @param {String} val
     */
   set prefix(val){
       if (val === null)return;

       this._prefix = val;
   }

   get isDebug(){
       return this._debug;
   }

    /**
     *
     * @param {Boolean} value
     */
   set isDebug(value){
       this._isDebug = value;
   }


}

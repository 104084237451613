export {StringUtils}

class StringUtils {

    constructor() {

    }

    static compareIgnoreCase(strA, strB) {
        return typeof (strA) === 'string' && typeof (strB) === 'string'
            ? strA.localeCompare(strB, undefined, {sensitivity: 'accent'}) === 0
            : strA === strB;
    }

    static isEmptyOrNull(str) {

        if (str === null) return true;

        if (typeof str === "string") {
            return (str === "");
        }

        return true;
    }

    static len(str) {
        if (str === null) return 0;

        if (typeof str === "string") {
            return str.length;
        }

        return 0;
    }
}
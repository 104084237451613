import {InternalZt} from "./InternalZt";

export class Zt {


    static init(config){
        window.__zt_instance = new InternalZt(config);
    }

    static destroy(){
        window.__zt_instance = null;
    }

    static store(name, value){
        Zt._zt.setItem(name, value);
    }

    static get(name){
        return Zt._zt.getItem(name);
    }

    static remove(name){
        Zt._zt.removeItem(name);
    }

    static listen(name, callback){
        Zt._zt.registerListener(name, callback);
    }

    static notify(name, value){

        console.debug(`[ZT:56020c2] ${name} emitted`);
        Zt._zt.push(name, value);
    }
    
    static get _zt(){

        if (window.__zt_instance == null) {
            Zt.init();
        }

        return window.__zt_instance;
    }
}


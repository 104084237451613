 export class PageSettings {

    constructor() {
    }

    static get _dataMap(){

        if (window.__page_settings == null) {
            window.__page_settings = {};
        }

        return window.__page_settings;
    }

    set(name, value){
        PageSettings.set(name, value);
    }

    static set(name, value){

        if (value == null){
            return;
        }

        if (name == null || name.length === 0)
        {
            return;
        }

        PageSettings._dataMap[name] = value;
    }

    get(name){
        return PageSettings.get(name);
    }

    static get(name){
        return PageSettings._dataMap[name];

    }


}

import {StoragePersistent} from "./storageTypes/StoragePersistent";
import {StorageInstance} from "./storageTypes/StorageInstance";
import {StorageConfig} from "./storageTypes/StorageConfig";
import {StoredItem} from "./storageTypes/StoredItem";

export class InternalZt {


     constructor(config) {

         this._persistStorage = null;
         this._config = null;


         if (config == null){
             this._config = this.__getDefaultConfig();
         }

         this._storage = this.__getStorage();
     }

     setItem(name, value){

         let item = new StoredItem(name, value);
         this.__getStorage().internalSetItem(name, item);
     }


    getItem(name){
        let item = this.__getStorage().internalGetItem(name);


        if (item != null){
            return item.value;
        }
        else{
            return null;
        }
    }


    removeItem(name){
        let item = this.__getStorage().internalGetItem(name);

        if (item != null){
            item.value = null;
        }
    }

    registerListener(name, func){
        let item = this.__getStorage().internalGetItem(name);

        if (item != null){
            console.debug(`[ZT:27d17bc] ${name} listener registered`);
            item.registerListener(func);
        }
        else{

            console.debug(`[ZT:17db0d1] ${name} listener registered`);
            item = new StoredItem(name);
            item.registerListener(func);
            this.__getStorage().internalSetItem(name, item);
        }
    }

    push(name, value){
        let item = this.__getStorage().internalGetItem(name);

        if (item == null){
            console.warn(`Listener(s) not found for ${name}. Emit ignored`);
            return;
        }

        try{
            console.debug(`Listener(s) found for ${name}.`);
            item.push(value);
        }
        catch(err){
            console.error(err);
        }

    }

     __getDefaultConfig(){
         return {
            storage: "persist",
            debug: false
         };
     }

     __getStorage(){

         try {
           let storage = this._config["storage"];
           let isDebug = this._config["debug"];

           if (storage != null){
               storage = "persist";
           }

           if (isDebug == null){
               isDebug = false;
           }

           let storageConfig = new StorageConfig();
           storageConfig._debug = isDebug;


           if (storage === "persist"){
               return new StoragePersistent(storageConfig);
           }
           else if (storage === "instance"){
               return new StorageInstance(storageConfig);
           }
           else{
               return new StoragePersistent(storageConfig);
           }
        }
        catch(e){
            console.error(e);
            throw e;
        }
     }


}
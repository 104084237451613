import {StorageBase} from "./StorageBase";

export class StorageInstance extends StorageBase {

    constructor(storageConfig) {
        super(storageConfig);
    }

    get config(){
        return super.config;
    }

    internalFireChange(name, value){
        super.internalFireChange(name, value);
    }

    internalFireError(name, value, e){
        super.internalFireError(name, value, e);
    }

    internalSetItem(name, item){
        try {

            if (item == null){
                return;
            }

            if (name == null){
                return;
            }

            if (name.length === 0){
                return;
            }

            let localName = this._generateUniqueName(name);
            this._localInstanceStorage.set(localName, item);
            console.debug(`[ZT:2085786] an item with a local name of ${localName} registered with the storage`);
        }
        catch(e){
            console.error(e);
            this.internalFireError(name, item, e);
        }
    }

    internalExistItem(name){
        try {

            if (name == null){
                return false;
            }

            if (name.length === 0){
                return false;
            }

            let localName = this._generateUniqueName(name);
            let exists = this._localInstanceStorage.has(localName);

            if (this.config.debug === true){
                if (exists) {
                    console.debug(`[ZT:1b16c47] local storage key ${name} exists`);
                }
                else{
                    console.debug(`[ZT:55e53e1] local storage key ${name} does not exists`);
                }
            }

            return exists;

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalGetItem(name){
        try {

            if (name == null){
                console.debug(`[ZT:4541c2e] name is null` );
                return null;
            }

            if(name.length === 0){
                console.debug(`[ZT:2be5fbc] name is empty` );
                return null;
            }

            let localName = this._generateUniqueName(name);
            let obj = this._localInstanceStorage.get(localName);

            if (this.config.debug === true){
                if (obj != null) {
                    console.debug(`[ZT:557f7fb] key ${name}. An object of type ${typeof obj} retrieved` );
                }
                else{
                    console.debug(`[ZT:232bafd] key ${name}. Value is null` );
                }
            }

            return obj;

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalRemoveItem(name){
        try {

            if (name == null || name.length === 0){
                return null;
            }

            let localName = this._generateUniqueName(name);

            let tmpItem = this._localInstanceStorage.get(localName);

            if (tmpItem != null){
                tmpItem.value = null;
            }

            if (this.config.debug === true){
                console.debug(`[ZT:334734f] key ${name} removed` );
            }


            this.internalFireChange(name, null);

        }
        catch(e){
            console.error(e);
            this.internalFireError(name, value, e);
        }
    }

    internalClearAll(){
        this._localInstanceStorage.clear();

        if (this.config.debug === true){
            console.debug(`[ZT:25fd438] clear all` );
        }
    }

    _generateUniqueName(name){
        return `${this.config.prefix}_i_${name}`;
    }

    get _localInstanceStorage(){

        if (window.__local_session_store == null) {

            window.__local_session_store = new Map();

            if (this.config.debug === true){
                console.debug(`[ZT:40786d4] new Map() created and pinned to the window object` );
            }
        }


        return window.__local_session_store;
    }
}